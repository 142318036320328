import { parseCookies } from "nookies";

import { decodeOrganizationFromCookies } from "pages/[tenant]/browse/utils/cachedBillboardOrganizationContentUtils";

import api from "shared/infra/services/api";

const forgotPasswordRequest = async (email: string) => {
  try {
    const { organization: encodedOrganization } = parseCookies();
    const organization = decodeOrganizationFromCookies(encodedOrganization);

    const response = await api.post(
      "/user/password/reset",
      {
        email,
      },
      {
        params: {
          org: organization?.id,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const forgotPassword = (email: string) => {
  const resetPass = forgotPasswordRequest(email);

  if (resetPass) {
    return resetPass;
  } else {
    return { message: "no response" };
  }
};

export default forgotPassword;
