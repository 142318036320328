import { User as IUser } from "../types/auth-context";

export type IFollowers = {
  data: IUser[];
  meta: {
    current_page: number;
    last_page: number;
    total: number;
  };
};

export type IFollowing = {
  data: IUser[];
  meta: {
    current_page: number;
    last_page: number;
    total: number;
  };
};

export type ILastTopics = {
  data: any;
  meta: {
    total: number;
  };
};

export type ICollections = {
  data: any;
  meta: {
    total: number;
  };
};

export type IComments = {
  data: any;
  meta: {
    total: number;
  };
};

export interface userType {
  followers: IFollowers;
  following: IFollowing;
  lastTopics: ILastTopics;
  loggedUser?: IUser;
  user: IUser;
  collections: ICollections;
  comments: IComments;
}

const userData: IUser = {
  id: 0,
  first_name: "",
  last_name: "",
  full_name: "",
  username: "",
  display_name: "",
  user_title: "",
  addresses: [],
  email: "",
  phone: "",
  photo: "",
  document: "",
  gender: "",
  biography: "",
  birthday: null,
  global_coins: 0,
  total_points: 0,
  active: false,
  admin: 0,
  blog_post_count: 0,
  blog_topic_count: 0,
  course_count: 0,
  lesson_count: 0,
  media_space_content_count: 0,
  site_public: "",
  address_public: "",
  phone_public: "",
  email_public: "",
  phones: [],
  cover_image: "",
  game_points: 0,
  game_credits: 0,
  promoter: false,
  unread_notifications: 0,
  unviewed_notifications: 0,
  has_recipient: false,
  superadmin: false,
  company_type: "",
  permissions: {
    blog: false,
    tags: false,
    flags: false,
    pages: false,
    users: false,
    forums: false,
    orders: false,
    coupons: false,
    courses: false,
    reports: false,
    comments: false,
    features: false,
    webhooks: false,
    main_menu: false,
    statement: false,
    transfers: false,
    user_menu: false,
    categories: false,
    sent_mails: false,
    spotlights: false,
    enrollments: false,
    forum_topics: false,
    transactions: false,
    bank_accounts: false,
    custom_scripts: false,
    general_config: false,
    shortcuts_menu: false,
    tracking_pixel: false,
    email_templates: false,
    product_bundles: false,
    webhook_historic: false,
    plan_subscriptions: false,
    media_space_contents: false,
    complementary_contents: false,
  },
  subscribe_now_cta: {
    group_id: null,
    group_slug: "",
    show: false,
    text: "string",
  },
  personal_documents: null,
  total_coins: 0,
  subscribed_orgs: [],
  console_role: null,
};

const user: userType = {
  followers: {
    data: [],
    meta: {
      current_page: 0,
      last_page: 0,
      total: 0,
    },
  },
  following: {
    data: [],
    meta: {
      current_page: 0,
      last_page: 0,
      total: 0,
    },
  },
  lastTopics: {
    data: [
      {
        id: 0,
      },
    ],
    meta: {
      total: 0,
    },
  },
  loggedUser: userData,
  user: userData,
  collections: {
    data: [],
    meta: {
      total: 0,
    },
  },
  comments: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

export default user;
