import styled, { css } from "styled-components";

interface IOrganizationSelectContainerProps {
  dropdownIsOpen: boolean;
}

export const OrganizationSelectContainer = styled.div<IOrganizationSelectContainerProps>`
  .selected-org {
    display: grid;
    grid-template-columns: auto 1fr auto;

    gap: 0.5rem;
    align-items: center;

    img {
      object-fit: contain;
    }

    svg {
      transition: 0.3s all;

      justify-self: end;

      ${({ dropdownIsOpen }) =>
        dropdownIsOpen &&
        css`
          transform: rotate(180deg);
        `}
    }
  }

  button {
    all: initial;

    box-sizing: border-box;

    :hover {
      cursor: pointer;
    }
  }

  > button {
    border: none;
    background-color: transparent;

    padding: 0.75rem 1rem !important;

    width: 100%;
    position: relative;

    ::before,
    ::after {
      content: "";
      position: absolute;
      width: 130%;
      height: 1px;
      background-color: ${({ theme }) =>
        theme.mode === "dark" ? "#ffffff14" : "#d5d5d5"};
    }

    ::before {
      top: 0px;
      left: -1rem;
    }

    ::after {
      bottom: 0px;
      right: -1rem;
    }
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    max-width: 17ch;

    color: ${({ theme }) => theme.console.pageTitleColor};
    font: 500 0.8125rem/1.2188rem "Inter";
  }

  img {
    border-radius: 2px;
  }

  ul {
    margin-bottom: 0;

    max-height: 0px;
    pointer-events: none;
    transition: 0.3s all;

    overflow-y: auto;
    overflow-x: hidden;

    ${({ dropdownIsOpen }) =>
      dropdownIsOpen &&
      css`
        max-height: 100px;
        pointer-events: all;
      `}

    &::-webkit-scrollbar {
      width: 4px;
      display: block;
    }

    &::-webkit-scrollbar-track {
      width: 4px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      display: block;
    }

    li {
      list-style: none;

      button {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        padding: 0.75rem 1rem !important;
        transition: 0.3s all;

        width: 100%;

        :hover {
          background-color: ${({ theme }) =>
            theme.mode === "dark" ? "#1c1c1c" : "#e1e1e1"};
        }
      }

      img {
        object-fit: contain;
      }
    }
  }
`;
