import { useCallback, useEffect } from "react";

import { parseCookies } from "nookies";

import { decodeOrganizationFromCookies } from "pages/[tenant]/browse/utils/cachedBillboardOrganizationContentUtils";

import api from "shared/infra/services/api";

import { captureException, captureMessage } from "@sentry/nextjs";

export const useAddOrganizationInfoInApi = () => {
  const handleAddOrganizationInfoInApi = useCallback(() => {
    try {
      const { organization: encodedOrganization } = parseCookies();
      const organization = decodeOrganizationFromCookies(encodedOrganization);

      if (!organization) return;

      api.interceptors.request.use(async (config) => {
        const isAdminConsoleRoute = config.url?.includes("/console");
        const urlAlreadyHasOrgParam =
          config.url?.includes("org=") || !!config?.params?.org;
        if (urlAlreadyHasOrgParam || isAdminConsoleRoute) return config;

        const customConfig = { ...config };

        customConfig.params = {
          ...(customConfig?.params ?? {}),
          org: organization?.id,
        };

        return customConfig;
      });
    } catch (error) {
      const errorMessage = "Erro (handleAddOrganizationInfoInApi)";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, [api]);

  useEffect(() => {
    handleAddOrganizationInfoInApi();
  }, [handleAddOrganizationInfoInApi]);

  return {};
};
