import { Dispatch, SetStateAction, useCallback } from "react";

import * as browser from "detect-browser";

import { decodeOrganizationFromCookies } from "pages/[tenant]/browse/utils/cachedBillboardOrganizationContentUtils";

import GlobalContextType from "shared/providers/context/GlobalContextType";
import { TenantData } from "shared/providers/context/initialStates/tenancy";

type ITheme = "light" | "dark";

const handleIsMobileAppClient = ({ cookies }) => {
  const appModeEnable = cookies?.isMobileAppClient || "0";
  const isMobileAppClient = appModeEnable === "1";
  isMobileAppClient
    ? sessionStorage?.setItem("appModeEnable", "1")
    : sessionStorage?.removeItem("appModeEnable");

  return { isMobileAppClient };
};

interface IUseAppInitialTheme {
  cookies: { isMobileAppClient: string; organization: string };
  query?: { tenant?: string; isMobileAppClient?: string };
  tenancy: TenantData;
  setState: Dispatch<SetStateAction<GlobalContextType>>;
}
const useAppInitialTheme = ({
  cookies,
  query,
  tenancy,
  setState,
}: IUseAppInitialTheme) => {
  const uuid = query?.tenant;

  const currentOS = String(browser?.detect()?.os).toLowerCase();
  const deviceIsIOS = currentOS?.includes("ios");

  const themeOption =
    tenancy?.settings?.["general-theme"]?.themeDarkModeOptions;

  const onlyDarkModeIsEnabled = themeOption === "only_theme_option";
  const onlyLightModeIsEnabled = themeOption === "inactive";
  const bothModesEnabled = themeOption === "active";

  const handleInitialPlatformThemeValues = useCallback(
    ({ menus }) => {
      const { isMobileAppClient } = handleIsMobileAppClient({ cookies });
      const organization = decodeOrganizationFromCookies(cookies?.organization);

      const themeOptionFromLocalStorage = localStorage?.getItem(
        "theme_mode",
      ) as ITheme;

      setState((prev) => ({
        ...prev,
        isDevMode: uuid === "dev",
        isMobileAppClient,
        isIOS: deviceIsIOS,
        tenancy: {
          ...tenancy,
          organization: {
            ...(tenancy?.organization ?? ({} as any)),
            ...organization,
          },
        },
        menus,
        theme: {
          value: onlyDarkModeIsEnabled
            ? "dark"
            : onlyLightModeIsEnabled
            ? "light"
            : themeOptionFromLocalStorage || "dark",
          bothModesEnabled,
        },
      }));
    },
    [
      bothModesEnabled,
      cookies,
      deviceIsIOS,
      onlyDarkModeIsEnabled,
      onlyLightModeIsEnabled,
      setState,
      tenancy,
      uuid,
    ],
  );

  return { handleInitialPlatformThemeValues };
};

export default useAppInitialTheme;
