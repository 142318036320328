import { parseCookies } from "nookies";

import { IOrganizationBillboardContent } from "pages/[tenant]/browse/types";

import { ISubscribedOrg } from "shared/providers/context/types/auth-context";

import { captureException, captureMessage } from "@sentry/nextjs";

export const makeSessionStorageKey = ({ tenantUuid, organizationId }): string =>
  `${tenantUuid}_${organizationId}_billboard_content`;

export const getCachedBillboardOrganizationContentFromSessionStorage =
  (): IOrganizationBillboardContent | null => {
    let result = null;

    try {
      const { slug: tenantUuid, organization: encodedOrganization } =
        parseCookies();

      const organization = decodeOrganizationFromCookies(encodedOrganization);

      const sessionStorageKey = makeSessionStorageKey({
        organizationId: organization.id,
        tenantUuid,
      });

      result = JSON.parse(sessionStorage.getItem(sessionStorageKey));
    } catch (error) {
      console.log(
        "Erro (getCachedBillboardOrganizationContentFromSessionStorage): ",
        error,
      );
      captureMessage(
        "Erro (getCachedBillboardOrganizationContentFromSessionStorage)",
      );
      captureException(error);
    }

    return result;
  };

export const saveCachedBillboardOrganizationContentInSessionStorage = (
  payload: IOrganizationBillboardContent,
): void => {
  try {
    const { slug: tenantUuid, organization: encodedOrganization } =
      parseCookies();

    const organization = decodeOrganizationFromCookies(encodedOrganization);

    const sessionStorageKey = makeSessionStorageKey({
      organizationId: organization?.id,
      tenantUuid,
    });

    sessionStorage.setItem(sessionStorageKey, JSON.stringify(payload));
  } catch (error) {
    console.log(
      "Erro (saveCachedBillboardOrganizationContentInSessionStorage): ",
      error,
    );
    captureMessage(
      "Erro (saveCachedBillboardOrganizationContentInSessionStorage)",
    );
    captureException(error);
  }
};

export const decodeOrganizationFromCookies = (
  encodedOrganization: string,
): ISubscribedOrg => {
  let result = {} as ISubscribedOrg;

  try {
    const parsedOrganization = JSON.parse(
      decodeURIComponent(decodeURIComponent(encodedOrganization ?? "")) || null,
    );

    if (!!parsedOrganization) result = parsedOrganization;

    result["id"] = result?.id === 0 ? null : result?.id;
  } catch (error) {
    console.log("Erro (decodeOrganizationFromCookies): ", error);
    captureMessage("Erro (decodeOrganizationFromCookies)");
    captureException(error);
  }

  return result;
};

export const encodeOrganizationToSaveInCookies = (
  organization: ISubscribedOrg,
): string => {
  let result = "";

  try {
    result = encodeURIComponent(JSON.stringify(organization));
  } catch (error) {
    console.log("Erro (encodeOrganizationToSaveInCookies): ", error);
    captureMessage("Erro (encodeOrganizationToSaveInCookies)");
    captureException(error);
  }

  return result;
};
