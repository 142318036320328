import api from "shared/infra/services/api";

import { captureException, captureMessage } from "@sentry/nextjs";

export const useRoleSlug = () => {
  const getRole = async (roleSlug: string, headers?: any) => {
    try {
      const { data } = await api.get(`/console-role/${roleSlug}`, {
        headers,
      });

      return data?.data;
    } catch (error) {
      console.log("Erro (getRole): ", error);
      captureException(error);
      captureMessage("Erro (getRole)");

      return false;
    }
  };

  return {
    getRole,
  };
};
